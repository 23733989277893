import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import OptionsTile from '~2-components/OptionsTile/OptionsTile';
import TileContainer from '~2-components/TileContainer/TileContainer';

const optionsTileEg = `import OptionsTile from '~2-components/OptionsTile/OptionsTile';

<OptionsTile
    title='' // Required
>
    // Content goes here
</OptionsTile>

`;

const SgOptionsTile = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allOptionsTiles = useStaticQuery(
        graphql`
        query SGAllOptionsTiles {
            allKontentItemOptionsTile {
                nodes {
                    system {
                        id
                        type
                    }
                }
            }
        }
    `);

    const optionTiles = allOptionsTiles.allKontentItemOptionsTile.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Options Tile</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={optionsTileEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <OptionsTile
                        title="WorkCover No fault benefits"
                    >
                        <p>WorkCover benefits include "weekly payments" of lost income and a financial contribution towards medical expenses such as:</p>
                        <ul>
                            <li>Ambulance and hospital expenses</li>
                            <li>Doctors and other medical attendances (including travel expenses)</li>
                            <li>Medications and aides</li>
                            <li>Personal and household expenses</li>
                            <li>Rehabilitation costs (including counselling and household assistance).</li>
                        </ul>
                    </OptionsTile>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h3>All LIVE Option Tiles in Kontent</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        tiles={optionTiles}
                        columns="three_columns"
                    />
                </div>
            </section>
        </SgLayout>
    );
};

export default SgOptionsTile;
